.order {
  flex: 4;
  padding: 20px;
}

.orderTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.orderTop {
  display: flex;
}

.orderTopRight {
  flex: 1;
}

.orderTopRight {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.orderInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: fill;
  margin-right: 20px;
}

.orderInfoTop {
  display: flex;
  align-items: center;
}

.orderName {
  font-weight: 600;
}

.orderInfoBottom {
  margin-top: 10px;
}

.orderInfoItem {
  width: 250px;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
}

.orderInfoValue {
  font-weight: 300;
}

.orderBottom {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.orderForm {
  display: flex;
  justify-content: space-between;
}

.orderFormLeft {
  display: flex;
  flex-direction: column;
}

.orderFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.orderFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.orderFormLeft > select {
  margin-bottom: 10px;
}

.orderUploadImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: fill;
  margin-right: 20px;
}

.orderFormRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.orderUpload {
  display: flex;
  align-items: center;
}

.orderButton {
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
