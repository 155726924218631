.orderList {
  flex: 4;
}

.orderListItem {
  display: flex;
  align-items: center;
}

.orderListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.orderListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.orderListDelete {
  color: red;
  cursor: pointer;
}
